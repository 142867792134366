@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Primary */
  --color-primary: rgb(0, 116, 97);
  --color-primary-400: rgb(160, 215, 162);
  --color-primary-600: rgb(0, 70, 82);
  --color-primary-800: rgb(0, 125, 143);
  --color-primary-900: rgba(0, 124, 143, 0.295);
  --color-primary-1000: rgba(66, 137, 138, 1);
  --color-primary-1100: rgba(0, 116, 97, 0.76);
  --color-primary-1200: rgb(72, 128, 118);

  /* Secondary */
  --color-secondary: rgba(107, 180, 253, 100%);
  --color-secondary-1000: rgba(114, 137, 218, 1);
  --color-secondary-800: rgba(107, 180, 253, 80%);
  --color-secondary-600: rgba(107, 180, 253, 60%);
  --color-secondary-400: rgba(107, 180, 253, 40%);
  --color-secondary-300: rgb(128, 159, 184);

  /* Light */
  --color-light: rgba(128, 159, 184, 100%);
  --color-light-800: rgba(128, 159, 184, 80%);
  --color-light-600: rgba(128, 159, 184, 60%);
  --color-light-400: rgba(128, 159, 184, 40%);

  /* Dark */
  --color-dark: rgba(6, 21, 43, 100%);
  --color-dark-800: rgba(6, 21, 43, 80%);
  --color-dark-600: rgba(6, 21, 43, 60%);
  --color-dark-400: rgba(6, 21, 43, 40%);

  /* Danger */
  --color-danger: rgba(234, 58, 61, 100%);
  --color-danger-800: rgba(234, 58, 61, 80%);
  --color-danger-600: rgba(234, 58, 61, 60%);
  --color-danger-400: rgba(234, 58, 61, 40%);

  /* Success */
  --color-success: rgba(26, 213, 152, 100%);
  --color-success-800: rgba(26, 213, 152, 80%);
  --color-success-600: rgba(26, 213, 152, 60%);
  --color-success-400: rgba(26, 213, 152, 40%);

  /* Warning */
  --color-warning: rgba(249, 185, 89, 100%);
  --color-warning-800: rgba(249, 185, 89, 80%);
  --color-warning-600: rgba(249, 185, 89, 60%);
  --color-warning-400: rgba(249, 185, 89, 40%);

  /* Gray */
  --color-gray-400: rgb(58, 68, 84);
  --color-gray-500: rgba(52, 60, 68, 1);
  --color-gray-600: rgba(54, 64, 69, 1);
  --color-gray-800: rgb(27, 32, 39);
  --color-gray-1000: rgba(171, 175, 179, 90);
  --color-gray-1100: rgb(112 111 111 / 90%);

  /* White */
  --color-white-400: rgb(218, 225, 230);
  --color-white-600: rgba(255, 255, 255, 0.8);
  --color-white-800: rgba(255, 255, 255, 1);

  /* Blue */
  --color-blue-400: rgb(124, 178, 247);
  --color-blue-500: rgb(18, 30, 66);
  --color-blue-600: rgb(134, 158, 181);
  --color-blue-700: rgb(48, 99, 204);
  --color-blue-800: rgb(10, 20, 41);
  --color-blue-900: rgb(7, 15, 29);
  --color-blue-1000: rgb(70, 163, 179);

  /* Green */
  --color-green-100: rgb(113, 216, 117);

  /* Gradient priamry */
  --color-gradient-primary: linear-gradient(
    90deg,
    --color-primary 0%,
    --color-primary-40 100%
  );

  /* Gradient secondary */
  --color-gradient-secondary: linear-gradient(
    90deg,
    --color-secondary 0%,
    --color-secondary-40 100%
  );
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url("./fonts/OpenSans-Semibold.ttf") format("opentype");
}
@font-face {
  font-family: "OpenSansBold";
  src: url("./fonts/OpenSans-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "OpenSansRegular";
  src: url("./fonts/OpenSans-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "OpenSansExtraBold";
  src: url("./fonts/OpenSans-ExtraBold.ttf") format("opentype");
}
body {
  margin: 0;
  font-family: "Avenir";
  /* src: url("./Avenir-Roman.otf") format("opentype"); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
code {
  font-family: "Avenir";
}

/* table */
table {
  border-collapse: collapse;
  width: 100%;
  border: none;
  border-spacing: 0px 10px;
}

table td,
#customers th {
  padding: 8px;
}

table tr {
  box-shadow: inset 4px 4px 30px 1px rgba(0, 0, 0, 0.13);
  border-radius: 30px;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: white;
}
tbody:before {
  content: "-";
  visibility: hidden;
}
tbody:after {
  content: "-";
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  left: 0;
  bottom: 0;
  color: transparent;
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  z-index: -1;
}

td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}
td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}
::placeholder {
  opacity: 0.5 !important;
}
::-webkit-input-placeholder {
  text-align: center;
  font-size: 14px;
  color: beige;
}
.gradientToDark {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-size: cover;
  background-position: center center;
}

.gradientToDark:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
}
.arrow-bottom:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--color-primary-600);
}
.arrow-right:after {
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -4px;
  content: "";
  display: block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(54, 64, 69, 1);
  transform: rotate(-90deg);
}
.card {
  position: relative;
  overflow: hidden;
}
.card > div {
  height: 100%;
  width: 100%;
}
.card .foreground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  background-color: #ffffff;
  transition: 0.5s ease;
  border: 1px solid var(--color-primary-400);
}
.foreground-active {
  transform: translateX(0) !important;
}
.jauge-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--color-primary-400);
}
.jauge-arrow-r {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--color-primary);
}
.mirror {
  transform: scaleX(-1);
}
.describe input {
  text-align: center;
  color: var(--color-primary-600);
  font-size: 14px;
}
.close {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
}
input[type="range"] {
  -webkit-appearance: none;
  height: 10px;
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(
    var(--color-primary-800),
    var(--color-primary-800)
  );
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: var(--color-primary-800);
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: var(--color-primary-900) 0px 0px 0px 8px;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: var(--color-primary-900) 0px 0px 0px 11px;
  transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
